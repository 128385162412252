.project-bj {
  .project-bj__baner {
    width: 100vw;
    margin-bottom: 100px;
    opacity: 0;
  }

  .project-bj__wrapper {
    .project-bj__cs {
      text-align: center;
      color: rgb(0, 164, 218);
    }
    .project-bj__blue-line {
      border: 1px solid rgb(0, 164, 218);
    }

    .project-bj__header {
      display: block;
      font-weight: bold;
      margin: 60px 0px 0px;
      position: relative;
      text-align: left;
      font-size: 14pt;

      @include gt-xs {
        font-size: 16pt;
      }

      @include gt-sm {
        font-size: 17pt;
      }

      @include gt-lg {
        font-size: 19pt;
      }

      &.project-bj__header--line {
        margin-bottom: 60px;
        text-align: center;
        &::before {
          content: "";
          position: absolute;
          bottom: -20px;
          left: 50%;
          width: 200px;
          transform: translateX(-50%);
          border: 2px solid rgb(60, 173, 135);
        }
        &.project-bj__header--line-blue {
          &::before {
            border: 2px solid rgb(0, 164, 218);
          }
        }
      }
    }

    ul {
      list-style: none;
      li {
        margin: 20px 0px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: -40px;
          top: -3px;
          width: 15px;
          height: 15px;
          border-radius: 100px;
          background-color: $color-dark-blue-light;
        }
      }
    }

    .project-bj__stats-container {
      .project-bj__stat {
        background-color: rgb(242, 242, 242);
        position: relative;
        text-align: center;
        padding: 75px 15px 15px 15px;
        margin-top: 75px;
        z-index: 1;
        img {
          position: absolute;
          width: 150px;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        h5 {
          font-weight: bold;
        }
      }
    }

    .project-bj__youtube {
      width: 100%;
      .project-bj__youtube-decoration {
        display: none;
      }

      .project-bj__video-container {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .project-bj__opinion {
      width: 100%;
      margin-top: 150px;
      position: relative;
      z-index: 1;
      .project-bj__opinion-avatar {
        width: 100%;
        max-width: 200px;
        border-radius: 350px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .project-bj__opinion-content {
        background-color: $color-dark-blue-light;
        color: #fff;
        padding: 120px 15px 15px;

        p {
          margin-top: 0px;
        }
      }
    }
  }

  .project-bg__decoration {
    display: none;
  }
}

@include gt-sm {
  .project-bj {
    .project-bj__wrapper {
      .project-bj__stats-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .project-bj__stat {
          width: 47%;
        }
      }
    }
  }
}

@include gt-md {
  .project-bj {
    .project-bg__decoration {
      display: block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      img {
        position: absolute;
        width: 25%;
        top: -5vw;
        right: 0;
      }

      &.project-bg__decoration--left {
        img {
          left: 0;
          right: auto;
        }
      }
      &.project-bg__decoration--top-reset {
        top: 0;
      }
    }

    .project-bj__wrapper {
      .project-bj__cs {
        text-align: right;
      }

      .project-bj__header {
        margin: 60px 0px 0px;

        &.project-bj__header--line {
          margin-bottom: 60px;
          text-align: left;
          &::before {
            bottom: -20px;
            left: -5vw;
            width: calc(5vw + 280px);
            transform: translateX(0);
          }
        }

        &.project-bj__header--right {
          width: 280px;
          left: 100%;
          transform: translateX(-280px);

          &.project-bj__header--line {
            &::before {
              right: -5vw;
              left: auto;
            }
          }
        }
      }

      ul {
        li {
          margin: 20px 0px;
          &::before {
            width: 20px;
            height: 20px;
          }
        }
      }

      .project-bj__opinion {
        margin-top: 40px;
        position: relative;
        padding-top: 50%;
        .project-bj__opinion-avatar {
          width: 40%;
          max-width: none;
          border-radius: 0px;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(0, 0);
          box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.3);
        }
        .project-bj__opinion-content {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 70%;
          padding: 15px 15px 15px 15%;
          z-index: -1;
          text-align: right;

          div {
            margin-top: 30px;
          }

          p {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@include gt-lg {
  .project-bj {
    .project-bj__wrapper {
      .project-bj__cs {
        text-align: right;
      }

      .project-bj__header {
        margin: 60px 0px 0px;

        &.project-bj__header--line {
          margin-bottom: 60px;
          text-align: left;
          &::before {
            bottom: -20px;
            left: -5vw;
            width: calc(5vw + 280px);
            transform: translateX(0);
          }
        }

        &.project-bj__header--right {
          width: 280px;
          left: 100%;
          transform: translateX(-280px);

          &.project-bj__header--line {
            &::before {
              right: -5vw;
              left: auto;
            }
          }
        }
      }

      ul {
        li {
          margin: 40px 0px;
          &::before {
            width: 20px;
            height: 20px;
          }
        }
      }

      .project-bj__stats-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .project-bj__stat {
          width: 22%;
          padding: 100px 25px 25px 25px;
          img {
            width: 200px;
          }
        }
      }

      .project-bj__section-line {
        margin: 80px 0px;
      }

      .project-bj__youtube {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .project-bj__youtube-decoration {
          position: relative;
          display: block;
          width: 30%;
          img {
            width: 100%;
          }
          &::before {
            position: absolute;
            bottom: 20%;
            padding: 30px 0px;
            background-color: rgb(60, 173, 135);
            width: 150%;
            content: "Głosowanie na walnym zgromadzeniu";
            text-align: center;
            z-index: 2;
            color: #fff;
          }
        }

        .project-bj__video-container {
          width: 65%;
          padding-bottom: 32.125%;
          border-radius: 75px;
          position: relative;
          background: rgb(175, 180, 192);
          background: linear-gradient(164deg, rgba(175, 180, 192, 1) 0%, rgba(250, 250, 250, 0) 100%);
          iframe {
            left: 50%;
            top: 50%;
            width: 97%;
            height: 95%;
            transform: translate(-50%, -50%);
            border-radius: 65px;
          }
        }
      }
      .project-bj__opinion {
        margin-top: 100px;
        .project-bj__opinion-content {
          padding: 40px 40px 40px 13%;

          div {
            margin-top: 60px;
            img {
              width: 60%;
            }
          }

          p {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
