.circle-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  fill: $color-text-light;

  @include gt-xs {
    width: 60px;
    height: 60px;
  }

  &__overlay {
    background: darken($color-secondary, 30%);
    position: absolute;
    opacity: 1;
    width: 100%;
    height: 100%;
    transition: ease-in 0.1s;
  }

  &__overlay-darker {
    background: $color-secondary;
    &:hover {
      background: lighten($color-secondary, 10%);
      transition: ease-in 0.1s;
      opacity: 1;
    }
  }

  &__overlay-lighter {
    &:hover {
      background: darken($color-secondary, 20%);
      transition: ease-in 0.1s;
      opacity: 1;
    }
  }

  &__ico {
    width: 65%;
    position: relative;
    pointer-events: none;
  }
}
