.paragraph {
  &.big {
    font-size: 1.1rem;
    @include gt-sm {
      font-size: 1.3rem;
    }
  }

  &.center {
    text-align: center;
  }
}
