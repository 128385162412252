.social-bar {
  display: flex;
  flex-direction: column;

  @include gt-md {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0;

    @include gt-md {
      text-align: left;
      width: 45%;
    }
  }

  &__list {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @include gt-md {
      width: 40%;
      justify-content: space-between;
    }
  }
}
