.nav-background {
  display: flex;
  justify-content: center;
  height: 0;

  &__subpage-item {
    background: url("/assets/subpage-nav-background-mobile.svg") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 60vw;

    @include gt-sm {
      background: url("/assets/subpage-nav-background.svg") no-repeat;
      width: 100%;
      background-size: 100%;
      height: 40vw;
    }
  }

  // BACKGROUND MAIN

  &__main-group {
    position: absolute;
    width: 120%;
    left: 0;
    top: 0;

    z-index: -1;
    background: url("/assets/background-1.jpg");
    background-repeat: no-repeat;
    background-size: contain;

    @include gt-sm {
      width: 100%;
    }

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
      width: 100%;
    }
  }

  &__main-item {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
  }

  &__main-overlay {
    fill: $color-dark-blue;
    opacity: 0.75;

    @include gt-md {
      opacity: 0.4;
    }
  }

  &__main-image {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
  }

  &__main-item-2 {
    position: absolute;
    background: url("/assets/background-shape-left.svg") no-repeat;
    background-size: 100%;
    left: 0;
    z-index: -1;
    height: 30.5vw;

    @include gt-sm {
      width: 45vw;
      height: 45vw;
      top: 40vw;
      left: -24vw;
    }
  }

  &__main-item-3 {
    position: absolute;
    background: url("/assets/background-shape-right.svg") no-repeat;
    background-size: 100%;
    right: 0;
    z-index: -1;
    height: 24.5vw;

    @include gt-sm {
      width: 42vw;
      height: 42vw;
      top: 25vw;
      right: -33vw;
    }
  }
}
