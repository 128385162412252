.bj-stat {
  background-color: rgb(242, 242, 242);
  position: relative;
  text-align: center;
  padding: 75px 15px 15px 15px;
  margin-top: 75px;
  z-index: 1;
  img {
    position: absolute;
    width: 150px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  h5 {
    font-weight: bold;
  }
}

@include gt-sm {
  .bj-stat {
    width: 47%;
  }
}

@include gt-sm {
  .bj-stat {
    width: 22%;
    padding: 100px 25px 25px 25px;
    img {
      width: 200px;
    }
  }
}
