.lang-button {
  position: relative;
  cursor: pointer;
  font-size: 10pt;
  font-weight: bold;
  color: $color-text-light;

  @include gt-md {
    font-size: 7pt;
  }

  @include gt-lg {
    font-size: 8pt;
  }

  &__select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $color-white;
    padding: 10px 10px;
    min-width: 70px;

    &--active {
      border-radius: 0 0 5px 5px;

      @include gt-sm {
        border-radius: 5px 5px 0 0;
      }
    }

    &--hidden {
      border-radius: 5px;
    }
  }

  &__select-text {
    text-transform: uppercase;
  }

  &__select-arrow {
    width: 10px;
  }

  &__list {
    position: absolute;
    align-items: center;
    border: 1px solid $color-white;
    width: 100%;
    border-radius: 5px 5px 0 0;
    bottom: 36px;
    background: $color-dark-blue;

    &--active {
      display: block;
    }

    &--hidden {
      display: none;
    }

    @include gt-sm {
      border-radius: 0 0 5px 5px;
      top: 34px;
      bottom: unset;
    }
    @include gt-md {
      top: 30px;
      background: transparent;
    }
  }

  &__list-option {
    padding: 12px 10px;
    text-align: left;
    transition: ease 200ms;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
