@import "./variables";

body {
  font-weight: 300;
  color: $color-text;
  line-height: 1.5;
}

.header {
  line-height: 1.3;
}

.subheader {
  line-height: 1.3;
}

.block-quote {
  line-height: 1.3;
  font-style: italic;
}

p {
  line-height: 1.5;
  font-size: 1rem;
  margin-bottom: $main-spacing;
}

strong {
  font-weight: 600;
}

a {
  color: darken($color-secondary, 30%);
  transition: $transition-time;

  &:link,
  &:visited {
    color: darken($color-secondary, 30%);
  }

  &:hover {
    color: darken($color-secondary, 20%);
  }

  &:active {
    color: darken($color-secondary, 30%);
  }
}
