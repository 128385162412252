.slide-down-arrow {
  width: 40px;
  height: 40px;
  //   margin: -20px 0 0 -20px;
  border-left: none;
  border-top: none;
  border-right: 3px $color-white solid;
  border-bottom: 3px $color-white solid;
  //   position: absolute;
  //   top: 36vw;
  //   left: 50%;
  transform: rotate(45deg);
  display: none;
  margin: 0 auto;

  @include gt-lg {
    display: block;
  }

  &:before {
    position: absolute;
    left: 50%;
  }

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    top: 50%;
    margin: -10px 0 0 -10px;
    border-left: none;
    border-top: none;
    border-right: 1px $color-white solid;
    border-bottom: 1px $color-white solid;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: arrow;
  }

  @keyframes arrow {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translate(-10px, -10px);
    }
  }
}
