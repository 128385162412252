.landing-portfolio {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;

  &__soon {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }

  &__case-study {
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 20px;
    }
  }

  &__header {
    text-align: center;
  }

  &__paragraph {
    max-width: 170px;
    text-align: center;
    margin: 1rem auto;
    @include gt-md {
      max-width: 225px;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    @include gt-md {
      justify-content: space-around;
    }
  }

  &__list-wrapper {
    position: relative;
    width: 80%;

    @include gt-sm {
      width: 50%;
    }
    @include gt-md {
      width: 33%;
    }
  }

  &__list-item {
    padding: 1rem;

    &__background {
      position: absolute;
      right: 0;
      transform: translate(-3%, -2%);
      max-width: none;
      top: 0;
      width: 200%;
      max-width: 640px;
    }
  }
}
