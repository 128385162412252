.contact-form {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    text-align: center;
    @include gt-md {
      text-align: left;
      display: inline;
      padding: 0;
    }
  }
  &__paragraph {
    text-align: center;
    display: block;
    flex-direction: column;
    padding: 1rem 0;
    span {
      display: inline;
    }

    @include gt-md {
      text-align: left;
    }
  }

  &__message {
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }

  &__success {
    background: rgb(62, 129, 66);
    color: #fff;
  }
  &__error {
    background: rgb(129, 62, 62);
    color: #fff;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
  &__list-field {
    outline: none;
    padding: 1rem 0.5rem;
    margin: 0.5rem 0;
    border-radius: 5px;
    resize: vertical;
  }

  &__button {
    margin: 0.5rem 0;
    align-self: center;
  }
}

.contact-form-buttons {
  margin-top: $main-spacing;
}
