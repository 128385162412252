.circle-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__ico-background {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 125px;
    height: 125px;
    margin: 0.5rem;
    background: $gradient-primary;
  }

  &__ico {
    width: 70%;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 240px;
    @include gt-md {
      max-width: 90%;
    }
  }

  &__paragraph {
    margin: 1rem;
  }
}
