.search-box {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &__button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    padding: 0 1rem 0 0.5rem;
    height: 100%;
    background: none;
    border: none;
    outline: none;
  }

  &__button-ico {
    height: 55%;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: 1px solid $color-dark-blue-darker;
    background: none;
    border-radius: 5px;

    padding: 0.7rem;
    font-size: 0.6rem;

    @include gt-lg {
      padding: 0.8rem;
      font-size: 0.7rem;
    }

    &::placeholder {
      color: $color-dark-blue;
      text-transform: uppercase;
    }
  }
}
