.project {
  p {
    font-size: 1.2rem;
    line-height: 2;
    max-width: 800px;
    margin: 0 auto $main-spacing * 2 auto;
  }

  ul {
    padding: 0;
    list-style-type: none;
    li {
      padding: 10px;
      margin-bottom: 10px;
      border-left: 10px solid $color-accent-blue;
    }
  }

  img {
    // border-radius: 5px;
    // box-shadow: 0px 19px 49px 0px rgba(151, 164, 167, 0.26);
  }
}

.project-scope {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: $main-spacing * 3;

  span {
    padding: 10px;
    border-radius: 5px;
    margin: 2.5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.project-links {
  margin-top: $main-spacing;
}
