body {
  background: $color-background;
  font-family: $font-primary;
}

#root {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
