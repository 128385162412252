.achievements {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__list-circle-tab {
    width: 100%;
    padding: 1rem;

    @include gt-sm {
      width: 45%;
    }

    @include gt-md {
      width: 30%;
    }
  }
}
