@import "../../../config/variables";

@mixin linearGradient($top, $bottom) {
  background: $top;
  /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%);
  /* W3C */
  /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);*/
  /* IE6-9 */
}

@mixin linearGradientHorizontal($left, $right) {
  background: $left;
  /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left, left right, color-stop(0%, $left), color-stop(100%, $right));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $left 0%, $right 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $left 0%, $right 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $left 0%, $right 100%);
  /* IE10+ */
  background: linear-gradient(to right, $left 0%, $right 100%);
  /* W3C */
  /*  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);*/
  /* IE6-9 */
}

/* preloader */
$size: 25px;
$margin: $size/5;
$connectorShort: $size/5 * 4;
$connectorLong: $size;

//The code:
$dotRowColors: (
  1: (
    start: #40af89,
    end: #1ead9a
  ),
  2: (
    start: #06aca8,
    end: #00a9c2
  ),
  3: (
    start: #00a9c7,
    end: #00a6db
  )
);
$connRowColors: (
  1: (
    start: #1ead9a,
    end: #06aca8
  ),
  2: (
    start: #00a9c2,
    end: #00a9c7
  ),
  3: (
    start: #00a9c7,
    end: #00a6db
  )
);

%common {
  content: " ";
  height: $size;
  width: $size;
  border-radius: 100%;
  background: $color-background;
  position: absolute;
  transform: translate(-50%, -50%);
}

.preloader {
  transition: 0.2s opacity;
  opacity: 1;
  z-index: 1001;

  &.ready {
    .logo {
      animation: logo 1.5s ease-in-out alternate;

      .connector-horizontal {
        display: block;
        animation: connectors 1.5s ease-in-out alternate;
      }

      .connector-vertical {
        display: block;
        animation: connectors-vertical 1.5s ease-in-out alternate;
      }

      .circle {
        opacity: 1;
        animation: circle 1.5s ease-in-out alternate;
      }
    }

    .title {
      opacity: 0;
      margin-top: 0;
      height: 0;
    }
  }

  &.loading {
    .logo {
      animation: pulsating 0.5s ease-in-out infinite alternate;

      .circle {
        margin: -$size;
        width: $size * 2;
        height: $size * 2;
      }
    }

    .connector {
      display: none;
    }

    .title {
      opacity: 0;
      margin-top: 0;
      height: 0;
    }
  }

  &.loaded {
    .title {
      height: 2.1rem;
      opacity: 1;
      margin-top: 80px;
    }
  }

  &.fade {
    opacity: 0;

    .title {
      height: 2.1rem;
      opacity: 1;
      margin-top: 80px;
    }
  }

  &.hidden {
    display: none;
  }

  &.circle {
    .title {
      display: none;
    }
  }
}

.preloader {
  .title {
    font-size: 2rem;
    transition: all 0.2s;
    opacity: 1;
    margin: 0;
    margin-top: 80px;
    width: 120px;

    > span {
      font-size: inherit;
    }
  }
}

.logo-container {
  width: 100%;
  padding: 100px 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);

  .logo {
    position: absolute;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%) rotate(45deg);

    .connector {
      position: absolute;
      transform-origin: 50% 50%;
      transform: translate(-50%, -50%);

      &.connector-horizontal {
        width: $size;
        height: $size/5 * 4;
        background: red; //  display: none;

        &:before {
          @extend %common;
          top: -38%;
          left: 50%;
        }

        &:after {
          @extend %common;
          top: 138%;
          left: 50%;
        }
      }

      &.connector-vertical {
        width: $size/5 * 4;
        height: $size;
        background: red; // display: none;

        &:before {
          @extend %common;
          top: 50%;
          left: -38%;
        }

        &:after {
          @extend %common;
          top: 50%;
          left: 138%;
        }
      }

      &.connector-1 {
        top: (2 * $margin + $size) * 2;
        left: $margin + (($size - $connectorShort) /2) + $connectorShort/2;
        @include linearGradient(#00a9c1, #00a8ca);
      }

      &.connector-2 {
        top: (2 * $margin + $size) * 2;
        left: 50%;
        @include linearGradient(#00a9c1, #00a8ca);
      }

      &.connector-3 {
        top: (2 * $margin + $size);
        left: 50%;
        @include linearGradient(#20ad99, #06aca9);
      }

      &.connector-4 {
        top: $margin + (($size - $connectorShort) /2) + $connectorShort/2;
        left: 33.333%;
        @include linearGradientHorizontal(#2fae91, #30ae91);
      }
    }

    .circle {
      width: $size;
      height: $size;
      border-radius: $size;
      background: #1ca5b9;
      margin: $margin;
      float: left;
      position: relative;
      z-index: 5;

      &.circle-4,
      &.circle-7 {
        clear: both;
      }

      &.circle-6 {
        display: none;
      }

      @for $i from 1 through 3 {
        @for $j from 1 through 3 {
          &.circle-#{($i - 1)*3 + $j} {
            //colors:
            background-color: #49ab70;
            @include linearGradient(
              map-get(map-get($dotRowColors, $i), "start"),
              map-get(map-get($dotRowColors, $i), "end")
            );
          }
        }
      }
    }
  }
}

@keyframes circle {
  0% {
    margin: -$size;
    width: $size * 2;
    height: $size * 2;
  }

  70% {
    margin: $size/5;
    width: $size;
    height: $size;
  }

  100% {
  }
}

@keyframes pulsating {
  0% {
    padding: $size * 2.35;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-450deg) scale(1);
  }

  100% {
    padding: $size * 2.35;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-450deg) scale(1.5);
  }
}

@keyframes connectors {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }

  70% {
  }

  80% {
    opacity: 1;
    width: $connectorLong;
    height: $connectorShort;
  }
}

@keyframes connectors-vertical {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }

  70% {
  }

  80% {
    opacity: 1;
    width: $connectorShort;
    height: $connectorLong;
  }
}

@keyframes logo {
  0% {
    padding: $size * 2.35;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-450deg);
  }

  70% {
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  100% {
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $color-background;
}

.main-preloader {
  .preloader {
    top: 0;
    position: fixed;
    z-index: 1006;
  }

  .logo-container {
    width: 100vw;
  }
}
