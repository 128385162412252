.decoration-dot {
  border-radius: 50%;
  position: absolute;
  display: none;

  &.accent-blue {
    background: $color-secondary;
  }
  &.accent-green {
    background: $color-primary;
  }
  &.white {
    background: #fff;
  }

  @include gt-sm {
    display: block;
  }
}
