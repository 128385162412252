.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: transparent;
  z-index: 1;
  padding: 15px 0;

  @include gt-sm {
    height: 8.5vw;
    align-items: center;
  }

  @include gt-md {
    align-items: center;
  }

  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__logo {
    height: 40px;
    z-index: 3;
  }

  &__logo-img {
    height: 100%;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background: $color-dark-blue;
    z-index: 2;
    padding: 20vw 15% 0 15%;
    transition: ease 0.5s;

    @include gt-md {
      background: none;
      position: relative;
      height: auto;
      padding: 0;
      height: auto;
      transition: none;
    }

    &--active {
      margin-right: 0;
    }

    &--hidden {
      margin-right: -100%;

      @include gt-md {
        margin: 0;
      }
    }

    &__wrapper {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      @include gt-md {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        overflow-y: initial;
        height: auto;
      }
    }
  }

  &__navigation-list {
    margin: 10px 0;

    padding: 0;
    list-style: none;

    @include gt-md {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      margin: 0;
      width: 100%;
    }

    &__item {
      width: 100%;
      margin: 10px 0;

      @include gt-md {
        width: auto;
      }
    }

    &__item-link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $color-text-light;
      padding: 10px;
      width: 100%;
      transition: border ease 200ms, background ease 200ms, color ease 200ms;
      font-weight: 700;
      text-transform: uppercase;

      &:link,
      &:visited,
      &:active {
        color: $color-text-light;
      }

      @include gt-md {
        font-weight: 400;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: $color-dark-blue;
        background: $color-white;
        transition: background ease 200ms, color ease 200ms;
        border-radius: 5px;

        @include gt-md {
          border-radius: 0;
        }
      }

      &--active {
        color: $color-dark-blue;
        background: $color-white;
        border-radius: 5px;
        @include gt-md {
          border-radius: 0;
        }
      }

      @include gt-md {
        font-size: 1vw;
        display: block;
        border: none;
        margin: 0;
        width: fit-content;
        border-bottom: 1px solid transparent;

        &--active {
          color: $color-white;
          background: transparent;
          border-bottom: 1px solid $color-white;
          border-radius: 5px;
          @include gt-md {
            border-radius: 0;
          }
        }

        &:hover {
          border-bottom: 1px solid $color-white;
          color: $color-white;
          background: transparent;
          transition: border ease 200ms;
          border-radius: 5px;
          @include gt-md {
            border-radius: 0;
          }
        }
      }

      @include gt-lg {
        font-size: 0.7vw;
      }
    }
  }

  &__hamburger-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }

  &__hamburger-icon {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    z-index: 3;
    cursor: pointer;

    @include gt-md {
      display: none;
    }

    &__item {
      height: 2px;
      width: 100%;
      background: $color-white;
    }
  }
  .navbar-buttons {
    width: auto;
    align-items: stretch;

    @include gt-md {
      flex: 0 1 auto;
      margin-left: $main-spacing;
    }
  }
}
