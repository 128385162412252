%button {
  display: flex;
  width: auto;
  border-radius: $main-spacing/2;
  background-color: darken($color-background, 1%);

  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  font-family: $font-primary;
  font-size: 1rem;
  border: none;
  box-shadow: $soft-shadow;
  padding: $main-spacing * 1.5 $main-spacing * 2;
  transition: $transition-time;
  outline: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;

  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }

  &:hover {
    background: darken($color-background, 5%);
    color: $color-text-light;
  }
}

.button-icon {
  margin-left: $main-spacing/2;
}

.button-text {
  text-align: center;
  z-index: 1;
}

.button {
  @extend %button;
  color: $color-text;
  &:link,
  &:active,
  &:visited {
    color: $color-text;
  }

  &:hover {
    background: darken($color-background, 5%);
    color: $color-text;
  }
}

.button-outline {
  border: 2px solid darken($color-secondary, 30%);
  color: darken($color-secondary, 30%);
  background: transparent;
}

.button-primary {
  @extend %button;
  background-color: $color-primary;
  color: $color-text-light;
  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }
  &:hover {
    color: $color-text-light;
    background: lighten($color-primary, 5%);
  }
}

.button-secondary {
  @extend %button;
  background-color: $color-secondary;
  color: $color-text-light;
  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }
  &:hover {
    color: $color-text-light;
    background: lighten($color-secondary, 5%);
  }
}

.button-danger {
  @extend %button;
  background: $color-danger;
  color: $color-text-light;
  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }
  &:hover {
    background: lighten($color-danger, 5%);
    color: $color-text-light;
  }
}

.button-success {
  @extend %button;
  background: $color-success;
  color: $color-text-light;
  &:link,
  &:active,
  &:visited {
    color: $color-text-light;
  }
  &:hover {
    background: lighten($color-success, 5%);
    color: $color-text-light;
  }
}

.button-small {
  padding: $main-spacing $main-spacing;
  font-size: 0.8rem;
}

.button-big {
  padding: $main-spacing * 2;
  font-size: 1.2rem;
}

.button-disabled {
  background: $color-disabled;
  opacity: 0.5;
  color: $color-text-light;
  cursor: not-allowed;

  &:hover {
    background: $color-disabled;
    color: $color-text-light;
  }
}

.button-icon {
  display: flex;
  align-items: center;
}

.button-left-icon {
  .button-icon {
    height: 1.5rem;
    margin: 0 $main-spacing/2 0 0;
  }
  &.button-no-text {
    .button-icon {
      margin: 0;
    }
  }
}
.button-right-icon {
  .button-icon {
    height: 1.5rem;
    margin: 0 0 0 $main-spacing/2;
  }

  &.button-no-text {
    .button-icon {
      margin: 0;
    }
  }
}

.button-round {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.55rem;
}
