@import "../../../config/variables";
@import "../../../config/mixins/breakpoints.scss";

.buttons-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  flex-direction: column;

  &.end {
    justify-content: flex-end;
  }

  .button {
    width: 100%;
    margin-bottom: $main-spacing;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.max {
    justify-content: stretch;
    flex: 1;
    .button {
      flex: 1;
    }
  }

  &.center {
    justify-content: center;
  }

  &.baseline {
    align-items: baseline;
  }

  @include gt-sm {
    &:not(.block) {
      flex-direction: row;
      .button {
        margin-right: $main-spacing;
        width: auto;
        &:last-child {
          margin-right: 0;
        }
      }
      @include gt-sm {
        .button {
          margin-bottom: 0;
        }
      }
    }
  }
}
