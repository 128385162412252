.portfolio-item {
  background: $color-white;
  border-radius: 5px;
  box-shadow: 0px 19px 49px 0px rgba(151, 164, 167, 0.26);
  margin-bottom: $main-spacing * 2;
  @include gt-sm {
    display: flex;
  }
}

.portfolio-item-left {
  padding: $main-spacing * 2;
  padding-bottom: 0;
  text-align: center;
  @include gt-sm {
    flex: 0 0 40%;
    padding: 40px;
  }
}

.portfolio-item-right {
  padding: $main-spacing * 2;
  width: 100%;
  @include gt-sm {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.portfolio-item-short {
  font-size: 0.8rem;
  font-weight: 600;
}

.portfolio-item-header {
  font-size: 1.4rem;

  @include gt-md {
    font-size: 2rem;
  }
}

.portfolio-item-image {
  border-radius: 5px;
  box-shadow: 0px 19px 49px 0px rgba(151, 164, 167, 0.26);
  max-height: 300px;
}
