.subpage-background {
  display: flex;
  justify-content: center;
  height: 0;
  height: 40vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;

  &__subpage-item {
    background: url("/assets/subpage-nav-background.svg") no-repeat;
    // background: url("/assets/subpage-nav-background-mobile.svg") no-repeat;
    background-size: 180%;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 60vw;

    @include gt-sm {
      background-size: 200%;
      background-position: 50% 25%;

      height: 40vw;
    }
    @include gt-md {
      // background: url("/assets/subpage-nav-background.svg") no-repeat;
      width: 100%;
      background-position: top center;
      background-size: 100%;

      height: 40vw;
    }
  }
}
