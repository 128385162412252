@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,500i,600,600i,700,700i,900");

$font-weights: (
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700
);

$font-primary: "Montserrat", sans-serif;

$color-primary: #3ab383;
$color-secondary: #00afdf;

$color-white: #fff;
$color-text: #034;
$color-text-light: #fff;

$color-grey: rgb(221, 221, 221);
$color-grey-dark: #9e9e9e;

$color-accent-blue: #00afdf;
$color-accent-blue-dark: #1fa1cd;
$color-accent-green-dark: #32946d;
$color-accent-green-light: #42cfa5;
$color-accent-green: #3ab383;

$color-soft-blue: #ecf4f6;

$color-background: #f2fafc;
$color-background-dark: #e7f5f8;
$color-background-darker: #c6dfe4;
$color-background-darkest: #d7e8ea;

$color-dark-blue-darker: #003242;
$color-dark-blue: #003344;
$color-dark-blue-light: #003c4f;
$color-dark-blue-lighter: #20556e;

$color-danger: rgb(128, 17, 17);
$color-success: rgb(8, 104, 32);
$color-disabled: rgb(117, 117, 117);

$gradient-primary: linear-gradient(215deg, rgba(58, 179, 131, 1) 0%, rgba(31, 161, 205, 1) 100%);

$breakpoints: (
  xs: 320px,
  xs-plus: 480px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1500px
);

$max-page-size: 1200px;

$transition-time: 0.3s;
$main-spacing: 10px;

$soft-shadow: 0px $main-spacing/2 $main-spacing * 2 (-0.5 * $main-spacing) rgba($color-text, 0.26);
