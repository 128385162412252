.landing-our-work {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container-top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-around;
    margin: 5vw 0;
    text-align: center;

    @include gt-md {
      background: url("/assets/our-work-background.png") center no-repeat;
      background-size: 100%;
      width: 675px;
      min-height: 570px;
    }

    @include gt-lg {
      width: 725px;
      min-height: 600px;
    }

    &__item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @include gt-sm {
        width: 50%;
      }

      @include gt-md {
        position: absolute;
        width: fit-content;
        height: fit-content;
        width: 33.3333%;

        &:nth-child(1) {
          left: 0px;
          bottom: -30px;
        }

        &:nth-child(2) {
          top: -10px;
        }

        &:nth-child(2) div {
          top: 80px;
        }

        &:nth-child(3) {
          right: 0;
          bottom: -30px;
        }
      }
    }

    &__item-background {
      background: $gradient-primary;
      border-radius: 50%;
      height: 180px;
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;

      @include gt-md {
        background: none;
        position: absolute;
        bottom: 100px;
      }

      @include gt-lg {
        bottom: 100px;
      }
    }

    &__item-icon {
      fill: $color-white;
      width: 35%;

      @include gt-md {
        width: 50%;
      }
    }

    &__item-paragraph {
      max-width: 220px;
    }
  }

  &__item-header {
    height: 70px;
    display: flex;
    align-items: center;
  }
}
