.landing-we-create {
  &__card-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 50px;

    &__item {
      width: 95%;
      text-align: center;
      margin: 10px 0;

      @include gt-sm {
        width: 90%;
      }

      @include gt-md {
        max-width: 32%;
        margin: 0;
      }

      @include gt-lg {
        max-width: 30%;
        margin: 0;
      }
    }
  }
}
