.bottom-cta {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 2px solid $color-dark-blue;
  width: 100%;
  padding: 12px;
  background-color: $color-background;
  z-index: 2;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
  transition: ease 500ms;

  &__message {
    margin: 1rem 36px;
  }

  &__btn {
    margin: 1rem 36px;
  }

  &__close-btn {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &--closed {
    margin-bottom: -100%;
  }

  @include gt-sm {
    flex-direction: row;
    text-align: initial;

    &__message {
      margin: 0 16px 0 36px;
    }

    &__btn {
      margin: 0 36px 0 16px;
    }
  }
}
