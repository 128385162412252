.newsletter {
  &__text-wrapper {
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form-input {
    margin: 0.5rem 0;
    outline: none;
    padding: 0.5rem;
    text-align: center;
    border-radius: 5px;
    width: 80%;
    font-size: 0.9rem;
    max-width: 400px;
    @include gt-sm {
      width: 60%;
    }
  }

  &__form-button {
    margin: 0.5rem;
  }
}

.newsletter-modal__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  transform: scale(1.2);
}

.newsletter__paragraph {
  width: 90%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.newsletter__form {
  width: 90%;
  max-width: 400px;
  background-color: rgb(221, 221, 221);
  margin-left: auto;
  margin-right: auto;
  padding: 20px 30px 20px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.newsletter__label {
  text-align: left;
  width: 100%;
  font-weight: bold;
  margin-top: 10px;
}

.newsletter__form-input {
  width: 100%;
  text-align: left;
  border-radius: 0;
  padding: 15px 10px;
}

.newsletter__form-button {
  width: 100%;
  margin-top: 20px;
}

.newsletter__modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 90vw;
  max-width: 900px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.newsletter__overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
}
