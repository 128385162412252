.landing-blog {
  padding: 1.5rem 0;
  position: relative;
  @include gt-md {
    padding: 4rem 0;
  }
  @include gt-lg {
    padding: 6rem 0;
  }
  @include gt-lg {
    padding: 8rem 0;
  }

  &__background-wrapper {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 50%;
    max-width: 850px;
    @include gt-md {
      display: block;
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
  }

  &__content-wrapper {
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    @include gt-md {
      align-items: flex-end;
      text-align: right;
      padding-left: 15%;
      width: 50%;
      padding-right: 1rem;
    }
  }

  &__header {
    padding: 0.5rem 0;
    width: 100%;

    @include gt-md {
      margin-right: auto;
    }
  }

  &__button {
    margin: 1rem 0 1rem 0;
    @include gt-md {
      margin: 1.5rem 0 2rem 0;
    }
  }
}
