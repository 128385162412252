.section-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;

  &.light {
    background-color: #fff;
  }
  &.dark {
    background-color: $color-dark-blue;
    color: $color-text-light;
  }
}
