.privacy-policy {
  ul {
    padding: 0;
    list-style-type: none;
    li {
      padding: 10px;
      margin-bottom: 10px;
      border-left: 10px solid $color-accent-blue;
    }
  }
  ol {
    max-width: 800px;
    &.no-indicator {
      list-style-type: none;
    }
    li {
      padding: 10px;
      line-height: 1.8rem;
    }
  }
}
