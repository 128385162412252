.filter-bar {
  display: flex;
  flex-direction: column;

  &__header {
    text-align: center;
  }

  &__search-box {
    margin: 1rem 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    @include gt-md {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__list-item {
    margin: 0.2rem 0;
    padding: 0.3rem 0;
    width: 100%;

    @include gt-md {
      margin: 0.2em;
      max-width: 48%;
    }

    @include gt-lg {
      margin: 0 0.5rem;
      padding: 0.5rem 0;
      width: initial;
      flex-grow: 1;
      flex-basis: 0;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
