.filter-button {
  display: flex;
  align-items: center;
  background: $color-accent-green;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  padding: 12px 12px;

  font-family: $font-primary;

  &:hover {
    .filter-button__overlay {
      opacity: 1;
    }

    .filter-button__checkmark {
      border: 1px solid $color-white;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    transition: ease 200ms;
  }

  &__checkmark {
    border: 1px solid $color-accent-green-light;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: $color-accent-green-light;
    position: relative;
  }

  &__checkmark-icon {
    color: $color-white;
    opacity: 0;
    transition: all ease 200ms !important;

    &--active {
      opacity: 1;
    }
  }

  &__text {
    color: $color-white;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;

    @include gt-lg {
      justify-content: center;
    }
  }
}
