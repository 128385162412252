.landing-hero {
  padding-top: 70vw;

  .hero-text {
    max-width: 85%;
  }

  @include gt-sm {
    padding-top: 12vw;
    margin-bottom: -6vw;
    color: $color-text-light;
    height: 70vw;

    .hero-text {
      max-width: 100%;
    }
    .hero-content {
      max-width: 60%;
    }
  }

  @include gt-lg {
    .hero-content {
      max-width: 40%;
    }
  }

  &__subheader {
    margin-bottom: $main-spacing * 3;
  }
}
