.contact-info__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: initial;
  margin-bottom: $main-spacing * 2;
  width: 100%;

  @include gt-md {
    justify-content: initial;
  }
}

.contact-info__ico {
  width: 20px;
}

.contact-info__text-wrapper {
  display: flex;
  flex-direction: column;

  margin-left: $main-spacing * 2;
}
