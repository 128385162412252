.chain-banner-short {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;

  &__text-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__list {
    display: flex;
    justify-content: center;
    width: 185px;
    margin: 2rem 0;
    @include gt-xs {
      width: 215px;
    }
    @include gt-lg {
      width: 305px;
    }
  }

  &__list-item {
    margin: 1.5rem 0;
    width: 200px;
    position: relative;
    align-items: center;
    @include gt-xs {
      margin: 1.6rem 0;
    }
    @include gt-sm {
      margin: 1.8rem 0;
    }
    @include gt-md {
      margin: 1.6rem 0;
    }
    @include gt-lg {
      margin: 2.5rem 0;
      width: 250px;
    }
  }

  &__list-item-align-right {
    @include gt-sm {
      text-align: left;
      left: 150px;
    }
    @include gt-lg {
      left: 200px;
    }
  }

  &__list-item-align-left {
    @include gt-sm {
      right: 150px;
      text-align: right;
    }
    @include gt-lg {
      right: 200px;
    }
  }
}
