.workshops__refs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  img {
    margin-top: 20px;
    width: 100%;
  }
}

@include gt-sm {
  .workshops__refs-container {
    img {
      width: 32%;
    }
  }
}
