.landing-contact {
  &__wrapper {
    display: flex;
    flex-direction: row;
    margin: 5vw 0;
  }

  &__form {
    width: 100%;
    margin: auto;

    @include gt-sm {
      width: 80%;
    }

    @include gt-md {
      width: 60%;
    }
  }

  &__background {
    display: none;

    @include gt-md {
      display: block;
      width: 40%;
      right: 10%;
      margin: 5% 0;
    }
  }

  &__social-bar {
    padding: 1rem;
  }
}
