.landing {
  .landing-paragraph {
    text-align: center;
    padding: 10px 0;

    strong {
      font-weight: 500;
    }
  }
}
