.post-card {
  background: $color-white;
  box-shadow: 0px 19px 49px 0px rgba(151, 164, 167, 0.26);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  width: fit-content;
  overflow: hidden;
  transition: box-shadow $transition-time, transform $transition-time;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 29px 69px 0px rgba($color-accent-blue, 0.26);
    transform: scale(1.02);
  }

  @include gt-sm {
    min-width: 130px;
    margin: 0;
  }

  &__background {
    height: 200px;
    width: 100%;
    background-size: cover;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
  }

  &__header {
    margin: 0.5rem 0;
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  &__info {
    font-style: italic;
    font-size: 0.7rem;
    color: $color-dark-blue;
    margin: 0.5rem 0;
    &__date {
      margin-bottom: 5px;
      font-weight: 600;
    }

    &__category {
      font-size: 0.8rem;

      span {
        padding: 3px;
      }
    }
  }

  &__description {
    margin: 0 0 0.6rem 0;
    color: $color-grey-dark;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  &__description-overlay {
    height: 75px;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(2, 157, 212, 0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(2, 157, 212, 0) 100%);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(2, 157, 212, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#029dd4",GradientType=1);
  }
}
