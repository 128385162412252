.bj-preview {
  position: relative;
  width: 100%;
  margin: 20px 0px;
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

@include gt-md {
  .bj-preview {
    margin: 20px 0px;
  }
}

@include gt-lg {
  .bj-preview {
    margin: 20px 0px;
    box-shadow: none;
  }
}
