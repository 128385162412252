.pagination {
  &__bar {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    list-style-type: none;
  }

  &__bar-list {
    display: flex;
  }

  &__bar-prev-btn {
    border-radius: 5px 0 0 5px;

    svg {
      transform: rotate(180deg);
    }
  }

  &__bar-next-btn {
    border-radius: 0 5px 5px 0;
  }

  &__bar-btn {
    border: 1px solid $color-accent-green;
    background-color: $color-accent-green;
    color: white;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;

    @include gt-sm {
      padding: 12px;
    }

    &--active {
      background-color: $color-accent-green-dark;
      border-color: $color-accent-green-dark;
    }

    &:hover {
      background-color: $color-accent-green-dark;
      border-color: $color-accent-green-dark;
    }
  }
}
