.container {
  max-width: $max-page-size;
  margin: 0 auto;
  padding: 0 25px;
  width: 100%;

  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
