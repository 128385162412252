.big-circle-tab {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  max-width: 300px;
  position: relative;
  margin: auto;
  transition: $transition-time;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }

  &__background {
    position: absolute;
    width: 200%;
    transform: translate(-50%, -2.5%);
    left: 0;
    top: 0;
    max-width: none;
  }

  &__img {
    position: relative;
    margin: 0.5rem 0;
    width: 220px;
    height: 220px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 5px 20px -5px rgba($color-primary, 0.2);
  }

  &__header {
    position: relative;
    margin: 1rem 0;
  }

  &__paragraph {
    position: relative;
    max-width: 220px;
    margin: 0;
  }
}
