.offer {
  &__button-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include gt-sm {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__button-border {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;

    @include gt-sm {
      width: 50%;
    }

    @include gt-lg {
      width: 30%;
    }
  }
}
