.circle-work-schema {
  margin: auto;
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 200px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__wrapper-left {
    order: 1;

    grid-column: span 3;

    @include gt-sm {
      grid-column: span 1;
      order: initial;
    }
  }

  &__wrapper-top {
    grid-column: span 3;
    order: 3;

    @include gt-sm {
      order: initial;
    }
  }

  &__wrapper-right {
    grid-column: span 3;
    order: 5;

    @include gt-sm {
      grid-column: span 1;
      order: initial;
    }
  }

  &__wrapper-text {
    font-weight: 700;
    color: $color-dark-blue;
    margin: 0.5rem 0;
  }

  &__wrapper-img {
    fill: $color-dark-blue;
    width: 100px;

    @include gt-sm {
      width: 60px;
    }
  }

  &__arrow {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    margin: 1.5rem auto 2rem auto;

    @include gt-sm {
      display: none;
    }
  }

  &__arrow-top {
    display: block;
    grid-column: span 3;
    order: 2;

    @include gt-sm {
      display: none;
    }
  }

  &__arrow-bottom {
    display: block;
    grid-column: span 3;
    order: 4;

    @include gt-sm {
      display: none;
    }
  }

  &__border {
    width: 30vw;
    height: 30vw;
    border: 2px solid $color-dark-blue;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
    display: none;

    @include gt-sm {
      display: flex;
    }
  }

  &__logo {
    width: 50%;
  }

  &__border-dot {
    position: absolute;
    width: 3%;
    height: 3%;
    border-radius: 50%;
    background: $color-dark-blue;
  }

  &__border-dot-left {
    left: -1.5%;
  }

  &__border-dot-right {
    right: -1.5%;
  }

  &__border-dot-top {
    top: -1.5%;
  }

  &__border-dot-bottom {
    bottom: -1.5%;
  }

  &__background {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient-primary;
  }
}
