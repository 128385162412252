.portfolio {
  .portfolio__filter-container {
    width: 100%;
    display: flex;
    padding-top: 50px;

    flex-wrap: wrap;

    @include gt-sm {
      padding-top: 0px;
    }

    .portfolio__button-container {
      width: 100%;

      .btn {
        margin-right: 0.5rem;
        margin-top: 10px;
      }

      @include gt-md {
        width: 48%;
      }

      @include gt-lg {
        width: auto;
      }
    }
  }

  &__header {
    text-align: center;
    margin: 1rem 0;

    @include gt-xs {
      max-width: 300px;
    }

    @include gt-sm {
      max-width: 350px;
    }

    @include gt-lg {
      max-width: 400px;
    }
  }

  &__gallery {
    margin: 2rem 0;
  }
}
