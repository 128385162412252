.blog {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__container-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include gt-sm {
      justify-content: space-between;
    }
  }

  &__container-list-item {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;

    @include gt-sm {
      width: 48%;
    }

    @include gt-lg {
      width: 30%;
    }
  }

  &__coming-soon {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
}

.newsletter-announce {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 50px;
  border-top: 1px solid #000;
  background-color: #fff;
  z-index: 2;

  .newsletter-announce__container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 15px;
    flex-grow: 1;

    @include gt-sm {
      font-size: 16px;
    }

    @include gt-lg {
      font-size: 17px;
    }
  }

  .newsletter-announce__button {
    border-radius: 5px;
    text-decoration: none;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 11px;
    border: none;
    padding: 5px 10px;
    background-color: #3ab383;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: #32946d;
    }

    @include gt-sm {
      font-size: 13px;
      margin-left: 10px;
    }

    @include gt-lg {
      padding: 12px 25px;
      font-size: 14px;
    }
  }

  .newsletter-announce__close-button {
    width: 80px;
    background-color: #fff;
    border: 0px;
    font-size: 30px;
    cursor: pointer;
  }
}
