.footer {
  height: 42vw;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
  font-size: 0.8rem;
  pointer-events: none;

  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $color-text-light;
    }
  }

  @include gt-sm {
    height: 16vw;
  }

  &__paragraph {
    pointer-events: all;
    color: $color-soft-blue;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    left: 0px;
    width: 100%;
    margin: auto;
    height: 10vw;

    @include gt-sm {
      height: fit-content;
      justify-content: flex-start;
      width: 47%;
      padding: 1vw;
    }
  }

  &__social-ico {
    width: 60%;
    cursor: pointer;

    &__background {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-white;
      position: absolute;
      top: 10vw;
      right: 10vw;
      border-radius: 50%;
      width: 60px;
      height: 60px;

      @include gt-xs {
        width: 80px;
        height: 80px;
      }

      @include gt-sm {
        top: auto;
        bottom: 5vw;
        left: 7vw;
        width: 9vw;
        height: 9vw;
      }

      @include gt-lg {
        width: 8vw;
        height: 8vw;
      }
    }
  }

  &__background {
    position: absolute;
    background: url("/assets/footer-shape-mobile.svg") bottom no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    left: 0;
    bottom: 0;

    @include gt-sm {
      background: url("/assets/footer-shape-desktop.svg") bottom no-repeat;
      background-size: 100%;
      width: 70%;
    }
  }

  &__border {
    padding: 0 1rem;
  }

  &__privacy {
    color: $color-soft-blue;
    transition: ease 200ms;
    font-weight: 500;

    &:hover {
      color: $color-white;
    }
  }

  &__icon {
    fill: #de4a4a;
    width: 20px;
    margin-left: 5px;
  }
}
