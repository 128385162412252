.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  @include gt-md {
    flex-direction: row;
    align-items: stretch;
  }

  &__content {
    text-align: center;
    color: $color-dark-blue;

    @include gt-md {
      width: 50%;
      text-align: left;
    }

    &__header {
      padding: 1rem 0;
    }

    &__quote-description {
      padding: 1rem 0;
    }

    &--active {
      position: relative;
      opacity: 1;
      transform: translateX(0);
      transition: ease 1.5s;
    }

    &--hidden {
      position: absolute;
      transition: ease 0.3s;
      opacity: 0;
      transform: translateX(-10px);
    }
  }

  &__customer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
  }

  &__image {
    position: absolute;
    width: 185px;
    height: 185px;
    top: 60px;
    left: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 50%;
    transition: $transition-time;
    background: #fff;

    @include gt-lg {
      width: 240px;
      height: 240px;
      top: 60px;
      left: 65px;
      padding: 30px;
    }

    &--active {
      opacity: 1;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;

    @include gt-md {
      position: absolute;
      top: 265px;
      transform: translateY(-25px);
      left: 170px;
      margin: 0;
    }

    @include gt-lg {
      top: 320px;
      left: 200px;
    }
  }

  &__customer-background {
    position: absolute;
    background: url("/assets/background-shape-semi-transparent-left.svg") no-repeat;
    background-size: 100%;
    width: 620px;
    height: 620px;
    top: 0;
    left: 50px;
    display: none;

    @include gt-md {
      display: flex;
      transform: translateY(-25px);
    }

    @include gt-lg {
      width: 730px;
      height: 730px;
    }
  }

  &__dots-item {
    border-radius: 50%;
    border: 2px solid $color-dark-blue-darker;
    margin: 5px;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    &--active {
      background: $color-dark-blue-darker;
    }
  }
}
