.circle-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 200px;

  &.primary {
    color: $color-primary;

    .circle-banner__ico {
      background-color: $color-primary;
    }
  }
  &.secondary {
    color: $color-secondary;
    .circle-banner__ico {
      background-color: $color-secondary;
    }
  }
  &.dark-blue {
    color: darken($color-secondary, 30%);
    .circle-banner__ico {
      background-color: darken($color-secondary, 30%);
    }
  }

  @include gt-sm {
    align-items: flex-end;
    overflow: visible;
    height: 25vw;
    padding: 0;
  }

  &__background {
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 335px;
    height: 390px;
    top: 0;
    @include gt-sm {
      width: 60vw;
      height: 70vw;
    }
  }

  &__text-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__ico {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    @include gt-sm {
      width: 17vw;
      height: 17vw;
    }

    svg {
      fill: $color-white;
      width: 70px;

      @include gt-sm {
        width: 80%;
      }

      @include gt-md {
        width: 60%;
      }
    }
  }
}

.circle-banner-align-left {
  @include gt-sm {
    flex-direction: row;
  }

  &__ico {
    transform: translate(45%, -65%);
    @include gt-sm {
      transform: translate(75%, -100%);
    }
  }

  &__background {
    background: url("/assets/shape-semi-transparent-offer-left.png") center no-repeat;
    background-size: 100%;
    transform: translate(-20%, 5%);
    @include gt-sm {
      transform: translate(-53.5%, -8%);
    }
  }

  &__text-group {
    text-align: center;
    margin: 1.5rem 0.5rem;
    max-width: 300px;
    height: 100%;
    @include gt-sm {
      width: 65%;
      text-align: left;
      max-width: none;
      margin: 0 0 0 auto;
    }
  }
}

.circle-banner-align-right {
  @include gt-sm {
    flex-direction: row-reverse;
  }

  &__background {
    background: url("/assets/shape-semi-transparent-offer-right.png") center no-repeat;
    background-size: 100%;
    transform: translate(20%, 5%);
    @include gt-sm {
      transform: translate(53.5%, -8%);
    }
  }

  &__ico {
    transform: translate(-45%, -65%);
    @include gt-sm {
      transform: translate(-75%, -100%);
    }
  }

  &__text-group {
    text-align: center;
    margin: 1.5rem 0.5rem;
    max-width: 300px;
    height: 100%;
    @include gt-sm {
      width: 65%;
      text-align: right;
      max-width: none;
      margin: 0 auto 0 0;
    }
  }
}
