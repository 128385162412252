.bottom-links {
  &__links {
    padding-bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    font-weight: 700;
  }

  &__btn {
    display: flex;
    align-items: center;
    color: $color-dark-blue;
    text-decoration: none;
    padding: 10px 5px;
    border-radius: 5px;
    transition: ease 200ms;

    span {
      padding: 0 5px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
