.post {
  margin-bottom: 42vw;

  .post__back-button-container {
    margin-top: 30px;
    width: 100%;

    @include gt-sm {
      margin-top: 0;
      width: 320px;
    }
  }

  @include gt-sm {
    margin-bottom: 15vw;
  }

  &__wrapper {
    margin-bottom: 0;
  }

  &__text-wrapper {
    border-bottom: 1px solid $color-grey;
    padding: 2em 0 0 0;
    display: flex;
    flex-direction: column;

    &-centered {
      justify-content: center;
      text-align: center;
    }
  }

  &__button-container {
    display: flex;
    margin-bottom: 30px;
  }
  &__back {
    font-weight: 700;
    display: flex;
    align-items: center;
    color: $color-dark-blue;
    text-decoration: none;
    padding: 10px 5px;
    border-radius: 5px;
    transition: ease 200ms;

    span {
      padding: 0 5px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.post-image {
  margin: 0px auto 30px;
  display: block;
  max-width: 800px;
  width: 100%;
}

.post-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0px auto;
  padding: 0 15px;

  img + em {
    display: block;
    text-align: center;
    margin: 15px 0;
  }

  blockquote + p {
    & > em {
      display: block;
      text-align: center;
      margin: 15px 0;
    }
    & > img {
      border-radius: 50%;
      width: 170px;
      height: 170px;
      border: 5px solid darken($color-background, 5%);
      display: block;
      margin: 0px auto;
      object-fit: cover;

      & + em {
        display: block;
        text-align: center;
        margin: 15px 0;
      }
    }
  }

  img {
    display: block;
    margin: 10px auto;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 600;
  }

  strong {
    font-weight: 600;
  }

  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic;
  }

  blockquote {
    padding-left: 30px;
    margin: 0;
    border-left: 5px solid darken($color-background, 5%);

    p {
      padding-bottom: 0;
      border-bottom: none;
      font-style: italic;
    }
  }

  pre {
    font-family: monospace;
    margin: 20px 0;
  }

  code {
    font-family: monospace;
    padding: 10px;
    border: 1px solid #eee;
    display: block;
    overflow: auto;
    margin: 20px 0;
  }

  a {
    font-weight: 500;
    transition: 0.3s;
    &:link,
    &:visited {
      color: $color-accent-blue;
    }
    &:hover {
      color: $color-dark-blue-lighter;
    }
    &:active {
      color: $color-dark-blue;
    }
  }

  p {
    // max-width: 800px;
    line-height: 1.8rem;

    padding-bottom: 20px;
    border-bottom: 1px solid darken($color-background, 5%);
    width: 100%;
    text-align: justify;
    margin-bottom: 30px;
    // &::first-letter {
    //     font-size: 1.2em;
    //     font-weight: 100;
    // }
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      padding: 10px;
      margin-bottom: 10px;
      border-left: 10px solid $color-accent-blue;
    }
  }
  ol {
    max-width: 800px;
    &.no-indicator {
      list-style-type: none;
    }
    li {
      padding: 10px;
      line-height: 1.8rem;
    }
  }
}
// == .entry {
//   position: relative;
//   padding-top: 20px;
//   padding-bottom: 40px;
//   flex: 0 1 100%;
//   margin-bottom: 20px;

//   .entry-content {
//     overflow: auto;
//     padding: 30px 15px;
//   }

//   &:nth-child(odd) {
//     .entry-content {
//     }
//   }
// }

// .entry-title {
//   position: relative;
//   z-index: 2;
//   color: #fff;
//   font-weight: 700;
// }

// .entry-meta-top {
//   // background: #eee;
//   padding: 15px;
//   text-align: right;

//   .entry-published {
//     margin: 10px;
//     font-weight: 700;
//   }
// }

// .blog-top {
//   max-width: 800px;
//   margin-bottom: 50px;
//   width: 100%;
// }

// .addthis-container {
//   text-align: center;
// }

.blog-content {
  h1 {
    margin: 40px 0px;
    font-weight: bold;
    line-height: 50px;
  }
  h2 {
    margin: 30px 0px;
    font-weight: bold;
  }
  h3 {
    margin: 20px 0px;
    font-weight: bold;
  }
  li {
    margin: 20px 0px;
    font-weight: normal;
    line-height: 35px;
  }
  ul {
    margin: 20px 0px 40px;
    font-weight: normal;
    line-height: 35px;
  }
  img {
    margin: 40px 0px 20px;
  }
  p {
    line-height: 2;
    font-size: 1.2rem;
  }
  blockquote {
    margin: 20px 0px;
  }
}
