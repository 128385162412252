.contact {
  margin-bottom: 0;
  &__form {
    width: 100%;
    @include gt-md {
      width: 60%;
    }
  }

  &__map {
    margin-top: 50px;
    z-index: 1;
  }

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include gt-md {
      flex-direction: row;
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__list-item {
    width: 50%;
  }

  &__group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include gt-md {
      flex-direction: row;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 30px auto;

    @include gt-md {
      width: 30%;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem 1.5rem;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
    width: 100%;

    .paragraph {
      margin: 0.2rem 0;
    }

    @include gt-md {
      justify-content: initial;
    }
  }

  &__ico {
    width: 30px;
  }
}

.contact__map {
  margin-bottom: 20vw;
  @include gt-sm {
    margin-bottom: 0;
  }
}
