.card {
  background: $color-white;
  box-shadow: 0px 19px 49px 0px rgba(151, 164, 167, 0.26);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 40px 15px 20px 15px;
  width: 100%;
  margin: 10px 0;
  flex: 0 0 100%;

  @include gt-sm {
    flex: 0 0 32%;
  }

  &.accent-blue {
    color: $color-accent-blue;

    .card__icon-background {
      background: $color-accent-blue;
    }
  }

  &.accent-green-light {
    color: $color-accent-green-light;
    .card__icon-background {
      background: $color-accent-green-light;
    }
  }

  &.dark-blue-darker {
    color: $color-dark-blue-darker;
    .card__icon-background {
      background: $color-dark-blue-darker;
    }
  }

  &__icon {
    width: 50%;
  }

  &__icon-border {
    border: 1px solid;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $main-spacing;
    @include gt-lg {
      width: 7.5vw;
      height: 7.5vw;
    }
  }

  &__icon-background {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    padding: 1rem 0 0 0;
  }

  &__description {
    color: $color-grey-dark;
    @include gt-xs {
      padding-left: 15%;
      padding-right: 15%;
    }
    @include gt-sm {
      padding-left: 20%;
      padding-right: 20%;
    }
    @include gt-md {
      padding-left: 20%;
      padding-right: 20%;
    }
    @include gt-lg {
      padding-left: 20%;
      padding-right: 20%;
    }
  }
}
