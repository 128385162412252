.subpage-wrapper {
  // padding-top: 14vw;
  padding-top: 0;
  position: relative;

  @include gt-sm {
    // padding-top: 6vw;
  }
  &__full-height {
    margin-bottom: 100vw;

    @include gt-sm {
      margin-bottom: 55vw;
    }
  }

  &__normal-height {
    margin-bottom: 42vw;

    @include gt-sm {
      margin-bottom: 15vw;
    }
  }

  &__main {
    overflow: hidden;
    width: 100%;
    position: relative;
    min-height: 100px;
    margin-bottom: 50px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // justify-content: center;
    position: relative;
    background: $color-dark-blue;
    background-size: cover;
    background-position: center;
    margin-bottom: 40px;
    padding-top: 110px;
    padding-bottom: 70px;
    padding-left: $main-spacing;
    padding-right: $main-spacing;
    color: $color-text-light;
    @include gt-sm {
      padding-top: 140px;
      padding-bottom: 100px;
    }

    &.with-background {
      padding-top: 150px;
      padding-bottom: 110px;
      @include gt-sm {
        padding-top: 190px;
        padding-bottom: 150px;
      }
    }
  }

  &__content {
    z-index: 1;
    position: relative;
    max-width: 800px;
  }

  &__overlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-dark-blue, 0.85);
  }

  &__decoration-dots-group {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
    z-index: -1;
    @media screen and (min-width: #{$max-page-size + 100}) {
      display: block;
    }
  }

  &__header {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 10px;
  }

  &__link {
    width: 50%;
    text-align: center;
    position: absolute;
    text-transform: uppercase;
    bottom: 0;

    @include gt-xs {
      width: fit-content;
    }

    @include gt-sm {
      bottom: 4vw;
    }
  }

  // &__overlay {
  //   height: 200px;
  //   position: absolute;
  //   width: 100%;
  //   top: 0;
  //   background: rgb(241, 250, 252);
  //   background: -moz-linear-gradient(0deg, rgba(241, 250, 252, 1) 0%, rgba(241, 250, 252, 0) 100%);
  //   background: -webkit-linear-gradient(0deg, rgba(241, 250, 252, 1) 0%, rgba(241, 250, 252, 0) 100%);
  //   background: linear-gradient(0deg, rgba(241, 250, 252, 1) 0%, rgba(241, 250, 252, 0) 100%);
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1fafc",endColorstr="#f1fafc",GradientType=1);
  // }
}
