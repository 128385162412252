.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // list-style-type: none;
  margin: 0;
  padding-left: $main-spacing * 2;
  > li {
    width: 100%;
    padding: 0 10px;
    margin-bottom: $main-spacing * 2;
    // border-left: 10px solid darken($color-secondary, 30%);
    p {
      margin: 0;
    }
  }
}
