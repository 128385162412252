.scroll-top-button {
  position: fixed;
  bottom: 100px;
  right: 33px;
  width: 40px;
  height: 40px;
  z-index: 5;
  border: none;
  outline: none;
  border-radius: 5px;
  transform: scale(1);
  transition: 0.3s;
  opacity: 1;
  background-color: darken($color-secondary, 30%);

  &:hover {
    transform: scale(1.2);
  }

  &.scroll-top-button--hidden {
    opacity: 0;
  }
}
