.circle-photo {
  position: relative;

  z-index: -1;
  width: 100%;
  height: 40vw;

  @include gt-sm {
    height: 28vw;
  }

  @include gt-md {
    height: 25vw;
  }
  &__wrapper {
    display: flex;
    position: absolute;
    width: 85vw;
    height: 85vw;

    @include gt-sm {
      width: 57vw;
      height: 57vw;
    }

    @include gt-md {
      width: 50vw;
      height: 50vw;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    margin: 0.3rem 0;
    width: 30vw;
    height: 30vw;
    @include gt-sm {
      width: 20vw;
      height: 20vw;
    }
    @include gt-md {
      width: 15vw;
      height: 15vw;
    }
  }

  &__header {
    margin: 0.2rem 0;
    width: 40%;
    @include gt-md {
      width: auto;
    }
  }
  &__paragraph {
    margin: 0.2rem 0;
    text-align: center;
    width: 40%;
    @include gt-md {
      width: auto;
    }
  }

  &__text-wrapper {
    position: relative;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.circle-photo__align-right {
  &__wrapper {
    background: url("/assets/background-shape-semi-transparent-right.svg") center no-repeat;
    background-size: 100%;
    right: 28vw;

    @include gt-sm {
      right: 50%;
    }
    @include gt-md {
      right: 0;
      transform: none;
    }
  }
  &__img {
    right: 0;
    transform: translate(-20%, 15%);

    @include gt-md {
      transform: translate(-33%, 30%);
    }
  }

  &__text-wrapper {
    justify-content: center;
    align-items: flex-end;
    @include gt-md {
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.circle-photo__align-left {
  &__wrapper {
    background: url("/assets/background-shape-semi-transparent-left.svg") center no-repeat;
    background-size: 100%;
    left: 28vw;
    @include gt-sm {
      left: 50%;
    }
    @include gt-md {
      transform: none;
      left: 0;
    }
  }

  &__img {
    left: 0;
    transform: translate(20%, 15%);

    @include gt-md {
      transform: translate(33%, 30%);
    }
  }

  &__text-wrapper {
    justify-content: center;
    align-items: flex-start;
    @include gt-md {
      justify-content: flex-end;
      align-items: center;
    }
  }
}
