.post-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  color: #fff;
  z-index: 1;

  @include gt-sm {
    flex-direction: row;
  }

  &__info {
    margin: 0.5rem 0;
    text-align: center;

    @include gt-sm {
      margin: 0;
    }

    &-date {
      margin-bottom: 10px;
    }

    &-categories {
      @include gt-sm {
        display: flex;
      }
    }

    &-category {
      padding: 10px;
      border-radius: 5px;
      margin: 2.5px;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  &__info-border {
    margin: 0 0.5rem;
  }

  &__social {
    display: flex;
  }

  &__social-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid $color-dark-blue;
    border-radius: 50%;
    margin: 0.5rem 0.5rem;

    &:hover {
      background: $color-dark-blue;
      color: $color-white;
      cursor: pointer;

      svg {
        fill: $color-white;
      }
    }

    @include gt-sm {
      margin: 0 0.5vw;
    }
  }

  &__social-svg {
    fill: $color-dark-blue;
    width: 50%;
  }
}
