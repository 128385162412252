.recent-post {
  cursor: pointer;

  &__main-header {
    text-align: center;
    margin: 1rem 0;
  }

  &__background {
    color: $color-text-light;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    @include gt-sm {
      flex-direction: row;
      align-items: flex-end;
      padding: 10vw 2.5rem 2.5rem 2.5rem;
      background-size: 100%;
    }
  }

  &__background-overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 50, 66, 0.861782212885154) 75%);
    width: 100%;
    height: 100%;
  }

  &__text-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 300px;

    @include gt-sm {
      text-align: left;
      flex: 0 0 60%;
      max-width: none;
    }
  }

  &__paragraph {
    margin: 0;
    display: none;

    @include gt-sm {
      display: block;
    }
  }

  &__header {
    margin: 0.5rem 0;
    text-align: center;

    @include gt-sm {
      text-align: left;
    }
  }

  &__info {
    display: flex;
    font-size: 0.8rem;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $main-spacing;

    @include gt-sm {
      justify-content: flex-start;
    }

    &__border {
      margin: 0 0.5rem;
    }
  }

  &__button {
    position: relative;

    @include gt-sm {
      margin-left: auto;
    }
  }
}
