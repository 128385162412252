.header {
  margin-bottom: $main-spacing;
  line-height: 1.3;
  font-weight: 700;
  &.light {
    font-weight: 300;
  }

  &.center {
    text-align: center;
  }

  &.header-h1 {
    font-size: 1.5rem;
    margin-bottom: $main-spacing * 3;
    @include gt-sm {
      font-size: 1.75rem;
    }
    @include gt-md {
      font-size: 2rem;
    }

    &.big {
      font-size: 1.5rem;
      @include gt-sm {
        font-size: 2rem;
      }
      @include gt-md {
        font-size: 2.5rem;
      }
    }
  }
  &.header-h2 {
    font-size: 1.1rem;

    margin-bottom: $main-spacing * 3;
    @include gt-sm {
      font-size: 1.45rem;
    }
    @include gt-md {
      font-size: 1.8rem;
    }
    &.big {
      font-size: 1.2rem;
      @include gt-sm {
        font-size: 1.8rem;
      }
      @include gt-md {
        font-size: 2.4rem;
      }
    }
  }
  &.header-h3 {
    font-size: 1rem;
    @include gt-sm {
      font-size: 1.3rem;
    }
    @include gt-md {
      font-size: 1.6rem;
    }

    &.big {
      font-size: 1.3rem;
      @include gt-sm {
        font-size: 1.55rem;
      }
      @include gt-md {
        font-size: 1.8rem;
      }
    }
  }

  &.no-bottom-margin {
    margin-bottom: 0;
  }
}
