.button-border {
  border-radius: 5px;
  border: 1px solid $color-accent-blue;
  height: 60px;
  width: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  padding: 0 0.5rem;
  color: $color-secondary;

  @include gt-md {
    width: 260px;
    height: 65px;
  }

  @include gt-md {
    width: 265px;
    height: 70px;
  }

  &__header {
    margin-top: $main-spacing;
  }

  &__background {
    background: $color-dark-blue;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    right: -5px;
    bottom: -5px;
    z-index: -1;
  }
}
