.landing-feedback {
  &__header {
    text-align: center;
  }

  &__carousel {
    margin-top: 2rem;
    min-height: 300px;

    @include gt-md {
      margin: 5vw 0 15vw 0;
    }
  }
}
